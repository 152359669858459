module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"David Gross","short_name":"David Gross","start_url":"/","background_color":"#e8e2de","theme_color":"#dd3418","display":"standalone","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"90f310d52c876cca5112c7146dd08b68"},
    },{
      plugin: require('../node_modules/gatsby-plugin-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
